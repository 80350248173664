/* Schenck's theme */

const _logo = './assets/logo/light.svg'
const _devices = './assets/widgets/devices.svg'
const _messages = './assets/widgets/messages.svg'

/**
 * If the "dark" and / or "light" keys are omitted, their value(s) will be calculated from "main",
 * according to the "tonalOffset" value.
 * If "contrastText" is omitted, its value will be calculated to contrast with "main", according to
 * the "contrastThreshold" value.
 *
 * @see https://material-ui.com/customization/palette/
 *
 * @example
 *    stateDescriptor_A: { // stateDescriptor -> warning, error, success, etc
 *     // light: will be calculated from palette.primary.main,
 *     main: '#ff4400',
 *     // dark: will be calculated from palette.primary.main,
 *     // contrastText: will be calculated to contrast with palette.primary.main
 *   },...
 *
 */
export default {
  direction: 'ltr',
  palette: {
    mode: 'light',
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    primary: {
      main: '#70777A',
      dark: '#595F61',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#0085CA',
      dark: '#0075B0',
      darker: '#0A405B',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#E20026',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#F8A700',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#005F8F',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#84BD00',
      contrastText: '#FFFFFF',
    },
    signal: {
      main: '#E20026',
      contrastText: '#FFFFFF',
    },
    active: {
      main: '#005F8F',
    },
    inactive: {
      main: '#595F61',
    },
    pending: {
      main: '#F8A700',
    },
    text: {
      primary: '#595F61',
      secondary: '#94999C',
      regular: '#595F61',
      light: '#94999C',
      inverted: '#FFFFFF',
      dark: '#000000',
      link: '#0085CA',
      description: '#8C8C8C',
    },
    divider: '#CACACA',
    border: {
      main: '#E6E7E8',
    },
    background: {
      main: '#FFFFFF',
      light: '#F3F5F6',
      dark: '#F5F5F5',
      selected: '#0085CA',
      content: '#FAFAFA',
    },
  },
  logo: {
    src: _logo,
    url: `url(${_logo})`,
    backgroundSize: '80%',
  },
  widget: {
    devices: `url(${_devices})`,
    messages: `url(${_messages})`,
  },
}
