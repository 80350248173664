/**
 * Schenck's serial field configuration
 */
export default [
  {
    key: 'rField',
    // variant: 'outlined',
    value: 'R',
    disabled: true,
    align: 'center',
    xs: 1,
  },
  {
    key: 'lettersField',
    helperText: '3-5 letters',
    condition: '(?=^.{3,5}$)(^[a-zA-Z]*$)',
    // clearable: true,
    xs: 6,
  },
  {
    key: 'numbersField',
    helperText: '4 numbers',
    condition: '(?=^.{4}$)(^[0-9]*$)',
    // clearable: true,
    xs: 5,
  },
]
