/**
 * Schenck's ticket types
 */
export default [
  {
    key: 'device_breakdown',
    value: 'Machine standstill',
    icon: 'crisis_alert',
    color: 'signal',
    deviceInput: true,
    deviceIdentify: false,
    deviceIdentifyRegister: false,
  },
  {
    key: 'technical_issue',
    value: 'Technical support',
    icon: 'on_device_training',
    deviceInput: true,
    deviceIdentify: false,
    deviceIdentifyRegister: false,
  },
  {
    key: 'clarification_spare_parts',
    value: 'Spare parts',
    icon: 'settings_suggest',
    deviceInput: true,
    deviceIdentify: false,
    deviceIdentifyRegister: false,
  },
  {
    key: 'require_service',
    value: 'Service, maintenance, calibration',
    icon: 'home_repair_service',
    deviceInput: true,
    deviceIdentify: false,
    deviceIdentifyRegister: false,
  },
  {
    key: 'digital_platform',
    value: 'Schenck One',
    icon: 'webhook',
    deviceInput: true,
    deviceIdentify: false,
    deviceIdentifyRegister: false,
  },
  {
    key: 'feedback_commendation',
    value: 'Suggestions, praise, criticism',
    icon: 'reviews',
    deviceInput: false,
  },
  {
    key: 'other',
    value: 'Other',
    icon: 'more_horiz',
    deviceInput: false,
  },
]
